export default {
  translation: {
    address: 'Adres',
    belgium: 'België',
    engineeringArchitecture: 'Ingenieur Architect',
    getInTouch: 'Neem contact op',
    intro: "Liesse is cool m'kay",
    introTitle: 'Over Liesse',
    phone: 'Telefoon',
    recentWork: 'Ontwerpen',
    skills: 'Vaardigheden',
    siteDescription: 'Belgische ingenieur architect',
  },
}
