export default {
  translation: {
    address: 'Address',
    belgium: 'Belgium',
    engineeringArchitecture: 'Architect Engineer',
    getInTouch: 'Get in touch',
    intro: "Liesse is cool m'kay",
    introTitle: 'About Liesse',
    phone: 'Phone',
    recentWork: 'Designs',
    skills: 'Skills',
    siteDescription: 'Belgian engineering architect',
  },
}
