export default {
  translation: {
    address: 'Adresse',
    belgium: 'Belgique',
    engineeringArchitecture: 'Ingénieur Architecte',
    getInTouch: 'Contactez-moi',
    intro: "Liesse est cool m'kay",
    introTitle: 'À propos de Liesse',
    phone: 'Téléphone',
    recentWork: 'Dessins',
    skills: 'Compétences',
    siteDescription: "Architecte d'ingénierie belge",
  },
}
